'use strict';
import _ from 'lodash';

angular.module('upDirectives').directive('upCustomField', [
	'$compile',
	function ($compile) {
		return {
			scope: {
				upCustomField: '=',
				upCustomInline: '=',
				ngDisabled: '=',
				isTrigger: '='
			},
			require: ['ngModel', '^?form', '^?upCustomFieldCompiler'],
			restrict: 'A',
			link: function (scope, element, attrs, requires) {
				var ctrl = requires[0];
				var form = requires[1];
				var compiler = requires[2];

				if (compiler) {
					compiler.addField();
				}

				var formatters = {
					date: function (val) {
						if (!(val instanceof Date)) {
							ctrl.$setViewValue(null);
						}
						return val;
					},
					integer: function (val) {
						var parsed = parseInt(val);
						if (!isNaN(parsed)) {
							ctrl.$setViewValue(parsed);
						}
						return val;
					},
					float: function (val) {
						var parsed = parseFloat(val);
						if (!isNaN(parsed)) {
							ctrl.$setViewValue(parsed);
						}
						return val;
					}
				};

				scope.$evalAsync(function () {
					var fieldObj = scope.upCustomField;
					var val = fieldObj.value || fieldObj.dropdownDefault;
					var input = angular.element('<input>');
					var type = fieldObj.datatype && fieldObj.datatype.toLowerCase();
					var data;

					switch (type) {
						case 'link':
							input[0].type = 'url';
							break;
						case 'email':
							input[0].type = 'email';
							break;
						case 'integer':
							if (scope.isTrigger) {
								const integerTags = Tools.TagsService.getTagsByEntity('Order').filter(
									tag => tag.type === 'Integer'
								);
								const allowedTags = integerTags.map(tag => `\\{\\{${tag.tag}\\}\\}`);
								const tagNamesRegex = allowedTags.join('|');
								input[0].type = 'text';
								input.attr('ng-pattern', `/^${tagNamesRegex}$|\\d+$/`);
							} else {
								input[0].type = 'number';
								input.attr('ng-pattern', '/^\\d+$/');
							}
							formatters.integer(val);
							break;
						case 'currency':
							input[0].type = 'text';
							input.attr('up-mask-value', '');
							break;
						case 'discount':
							input[0].type = 'number';
							formatters.float(val);
							break;
						case 'percent':
							input[0].type = 'number';
							input[0].min = 0;
							input[0].max = 100;
							input[0].step = 'any';
							formatters.float(val);
							break;
						case 'date':
							input[0].type = 'date';
							formatters.date(val);
							break;
						case 'time':
							input[0].type = 'time';
							input.attr('as-string', true);
							break;
						case 'string':
							break;
						case 'boolean':
							input[0].type = 'checkbox';
							input.attr('up-toggle', true);

							if (attrs.asToggle !== undefined) {
								input.attr('as-buttons', true);
							}
							break;
						case 'text':
							input = angular.element('<textarea>');
							if (attrs.textRows) {
								input.attr('rows', attrs.textRows);
							}
							break;
						case 'select':
							data = scope.upCustomField.default;
							if (
								data.indexOf(ctrl.$modelValue) === -1 &&
								ctrl.$modelValue !== null &&
								ctrl.$modelValue !== undefined &&
								!Array.isArray(ctrl.$modelValue)
							) {
								data.push(ctrl.$modelValue);
							}
							scope.select2config = {
								allowClear: !fieldObj.obligatoryField,
								data: data,
								multiple: attrs.multiple !== undefined,
								placeholder: fieldObj.name,
								formatSelection: function (object, container, escape) {
									return escape(object);
								},
								formatResult: function (object, container, query, escape) {
									return escape(object);
								},
								id: function (object) {
									return object;
								},
								matcher: function (term, undef, item) {
									const text = _.isString(item) ? item : item.text;
									return text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
								}
							};

							input[0].type = 'hidden';
							input.attr('ui-select2', 'select2config');
							break;
						case 'user':
						case 'users':
							data = window.Tools.AppService.getActiveUsers().map(function (user) {
								return { id: user.id, text: user.name };
							});

							scope.select2config = {
								allowClear: !fieldObj.obligatoryField,
								data: data,
								multiple: type === 'users',
								placeholder: fieldObj.name,
								separator: ',',
								formatSelection: function (object, container, escape) {
									return escape(object.text);
								},
								formatResult: function (object, container, query, escape) {
									return escape(object.text);
								},
								id: function (object) {
									return object.id;
								},
								matcher: function (term, undef, item) {
									const text = _.isString(item) ? item : item.text;
									return text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
								}
							};

							input[0].type = 'hidden';
							input.attr('ui-select2', 'select2config');
					}

					const maxLength = fieldObj.maxLength && input.is('input') ? fieldObj.maxLength : undefined;
					const shouldSetMaxLength = ['user', 'users', 'boolean', 'date'].indexOf(type) === -1;

					input.attr({
						maxlength: shouldSetMaxLength ? maxLength : undefined,
						required: fieldObj.obligatoryField && fieldObj.editable ? true : undefined,
						name: attrs.name || 'upCustomField_' + fieldObj.id,
						'ng-model': '$parent.' + attrs.ngModel,
						'ng-disabled': 'ngDisabled' || false,
						'ng-readonly': attrs.ngReadonly || false,
						class: attrs.class,
						placeholder: attrs.placeholder || '',
						disabled: !fieldObj.editable || fieldObj.locked ? true : undefined,
						'data-test-id':
							(attrs.testIdPrefix ? attrs.testIdPrefix + '-' : '') +
							'cf-' +
							fieldObj.name.replace(new RegExp(' ', 'g'), '-')
					});

					if (attrs.ngChange) {
						input.attr('ng-change', '$parent.' + attrs.ngChange);
					}

					if (attrs.upFocus) {
						input.attr('up-focus', '$parent.' + attrs.upFocus);
					}

					if (attrs.placeholder) {
						input.attr('placeholder', attrs.placeholder);
					}

					input.on('keydown', function (e) {
						if (type === 'integer' && e.keyCode === 69) {
							e.preventDefault();
							return;
						}
					});

					// compile
					element.replaceWith(input);

					if (compiler) {
						var compiled = compiler.getCompiled(fieldObj.id);
						if (!compiled) {
							compiled = $compile(input);
							compiler.setCompiled(fieldObj.id, compiled);
						}
						compiled(scope);
					} else {
						$compile(input)(scope);
					}
					if (ctrl) {
						if (type === 'date') {
							ctrl.$formatters.push(formatters.date);
						} else if (type === 'integer') {
							ctrl.$formatters.push(formatters.integer);
						} else if (type === 'discount' || type === 'percent') {
							ctrl.$formatters.push(formatters.float);
						}
						ctrl.$render();
					}

					if (form) {
						var formObj = form['upCustomField_' + fieldObj.id];
						if (formObj) {
							formObj.$$customField = fieldObj;
						}
					}
					if (compiler) {
						compiler.fieldDone(form);
					} else {
						setTimeout(function () {
							if (form && !form.$pristine) {
								form.$setPristine(true);
							}
							scope.$digest();
						});
					}
				});
			}
		};
	}
]);

angular.module('upDirectives').directive('upCustomFieldCompiler', function () {
	return {
		controller: [
			'$scope',
			function ($scope) {
				var compiledFields = {};
				var fields = 0;
				var processedFields = 0;

				this.addField = function () {
					fields++;
				};

				this.fieldDone = function (form) {
					processedFields++;
					if (processedFields === fields) {
						setTimeout(function () {
							if (form) {
								form.$setPristine(true);
							}
							$scope.$digest();
						});
					}
				};

				this.setCompiled = function (id, compiled) {
					compiledFields[id] = compiled;
				};

				this.getCompiled = function (id) {
					return compiledFields[id];
				};
			}
		]
	};
});

angular.module('upDirectives').directive('upCustomFieldReact', [
	'$datepicker',
	'$filter',
	'$safeApply',
	function ($datepicker, $filter, $safeApply) {
		return {
			scope: {
				ngModel: '=ngModel',
				field: '=upCustomFieldReact',
				resource: '=resource',
				accountForm: '=accountForm',
				ngDisabled: '=',
				useNewTime: '=useNewTime',
				useNumberInput: '=useNumberInput',
				useExternalDisabled: '=useExternalDisabled'
			},
			require: 'ngModel',
			replace: true,
			template: '<div></div>',
			restrict: 'A',
			link: function ($scope, $element, $attrs, ngModel) {
				let firstElement = $element[0];
				var placeholder = $attrs.placeholder || null;
				var name = $attrs.name || null;
				var filterValues;
				var tabindex = $element.attr('tabindex');
				var entity = $element.attr('entity');
				$element.removeAttr('tabindex');
				var validate = function () {
					if (
						$scope.field.obligatoryField &&
						((!$scope.field.value && $scope.field.value !== 0) ||
							$scope.field.value === '' ||
							(Array.isArray($scope.field.value) && $scope.field.value.length === 0))
					) {
						ngModel.$setValidity('required', false);
					} else {
						ngModel.$setValidity('required', true);
					}
					$safeApply($scope);
				};

				var render = function () {
					if (!firstElement) {
						return;
					}
					ReactDOM.render(
						React.createElement(ReactTemplates.customFieldInput, {
							field: _.clone($scope.field),
							filterValues: filterValues,
							placeholder: placeholder,
							usenewdate: $attrs.usenewdate,
							lookupType: $attrs.lookup,
							ngModel: ngModel,
							name: name,
							entity: entity,
							useNewTime: $scope.useNewTime,
							useNumberInput: $scope.useNumberInput,
							updateOnBlur: $attrs.updateOnBlur,
							resource: $scope.resource,
							disabled: $scope.ngDisabled,
							multiple: $attrs.multiple !== undefined,
							filter: $attrs.filter !== undefined,
							tabindex: tabindex,
							useExternalDisabled: $attrs.useExternalDisabled,

							valueChange: function (value, data, noFrills) {
								if (noFrills) {
									$scope.field.value = value;
								} else {
									$scope.field.value = value;
									filterValues = data;
									ngModel.$setViewValue(value);
									validate();
								}
							}
						}),
						firstElement,
						function () {
							if ($scope.accountForm) {
								setTimeout(function () {
									$scope.accountForm.$$renameControl(
										ngModel,
										name || 'upCustomField_' + $scope.field.id
									);
								}, 0);
							}
						}
					);
					validate();
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					'field',
					function (field) {
						if (field) {
							render();
						}
					},
					true
				);

				$scope.$watch(
					'ngModel',
					function (value) {
						if (value !== $scope.field.value) {
							$scope.field.value = value;
							render();
						}
					},
					true
				);

				$scope.$watch(
					'ngDisabled',
					function (value) {
						if (value !== undefined) {
							render();
						}
					},
					true
				);
			}
		};
	}
]);
