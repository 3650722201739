'use strict';
import _ from 'lodash';
import React from 'react';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { hasTodoCustomFields } from 'App/babel/helpers/todo';

const { hasCMWithRR, hasRRWithCM } = require('App/helpers/salesModelHelpers');
const expandedSectionLSKey = 'admin-expandedSections';

ReactTemplates.admin.sidebar = window.ReactCreateClass({
	getInitialState: function () {
		// Temporarily store expanded sections in local storage to sync between angular/react admin
		this.localStorage = new LocalStorage();
		const LSExpandedSections = this.localStorage.getValue(expandedSectionLSKey);
		const expandedSections = LSExpandedSections || ['account'];
		if (Tools.AppService.getSelf().teamLeader && !expandedSections.includes('crm')) {
			// for fortnox, maybe change logic when we have more partners
			expandedSections.push('crm');
		}

		// Doing this in UNSAFE_componentWillMount didn't update translations after changing language for some reason so doing it here instead
		this.initializeItems();

		return {
			searchStr: '',
			expandedSections: expandedSections,
			expandedSubSections: [],
			currentUrl: this.props.rootData.currentUrl,
			avatar: ''
		};
	},

	UNSAFE_componentWillReceiveProps: function (props) {
		if (
			props.rootData.outsideSearch !== this.props.rootData.outsideSearch &&
			props.rootData.outsideSearch !== this.state.searchStr
		) {
			this.setState({ searchStr: props.rootData.outsideSearch });
		}
	},

	initializeItems: function () {
		var t = Tools.$translate;
		this.lang = {
			search: t('default.search'),
			account: t('admin.account'),
			marketingAutomation: t('admin.marketingAutomation'),
			followup: t('admin.followup'),
			apps: t('admin.apps'),
			findNewApps: t('admin.findNewApps'),
			myProfile: t('default.myProfile'),
			noResults: t('default.noResults'),
			notificationCenterTitle: t('admin.notificationcenter.sidebar.title'),
			appointmentAvailabilityTitle: t('admin.appointmentavailability.sidebar.title'),
			mailSignatureTitle: t('admin.mailSignature.sidebar.title'),
			remindersTitle: t('reminders.automatedReminders.title'),
			customerSupport: t('admin.customerSupport'),
			pipeline: t('admin.pipeline')
		};

		var businessRules = this.getBusinessRules();
		var securityItems = this.getSecurityItems();
		var accountSettingsItems = this.getAccountSettingsItems();
		const self = Tools.AppService.getSelf();

		var FeatureHelper = Tools.FeatureHelper;
		var isAdmin = self.administrator;
		var isBillingAdmin = self.billingAdmin;
		var isTeamleader = self.teamLeader;
		var isMaAdmin = isAdmin || self.userParams.mailAdmin;

		const hasGoals = Tools.FeatureHelper.hasSoftDeployAccess('GOALS');
		const hasToDo = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		const hasSupport = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT);
		const hasSupportAndIsAdmin = hasSupport && isAdmin;
		const hasTicketRules = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_RULES');
		const shouldShowOldActivityQuota = !(hasGoals && hasToDo);
		const hasTicketResponseTemplates = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_RESPONSE_TEMPLATES');

		this.metadata = Tools.AppService.getMetadata();

		this.accountItems = [
			{
				title: t('admin.businessRules'),
				visible: isAdmin && businessRules.length,
				state: businessRules.length ? businessRules[0].state : null,
				keywords: [t('admin.businessRules').toLowerCase()],
				children: businessRules
			},
			{
				title: t('admin.usersAndRoles'),
				visible: isAdmin,
				state: 'usersAndRoles',
				keywords: [t('admin.usersAndRoles').toLowerCase()],
				children: [
					{
						title: t('admin.activeInvites'),
						state: 'activeInvites',
						visible: isAdmin,
						keywords: [t('admin.activeInvites').toLowerCase()]
					}
				]
			},
			{
				title: t('admin.dataManagement'),
				visible: isAdmin,
				state: 'imports',
				keywords: [t('admin.dataManagement').toLowerCase()],
				children: [
					{
						title: t('import.pagetitle'),
						visible: isAdmin,
						state: 'imports',
						keywords: [t('import.pagetitle').toLowerCase()]
					},
					{
						title: t('admin.anonymization.name'),
						visible: isAdmin,
						state: 'anonymization',
						keywords: [t('admin.anonymization.name').toLowerCase()]
					}
				]
			},
			{
				title: t('default.userdefinedobjects'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.UDO),
				state: 'userdefinedobjects',
				keywords: [t('default.userdefinedobjects').toLowerCase()]
			},
			{
				title: t('admin.accountSettings'),
				visible: isAdmin && accountSettingsItems.length,
				state: accountSettingsItems.length ? accountSettingsItems[0].state : null,
				keywords: [t('admin.accountSettings').toLowerCase()],
				children: accountSettingsItems
			},
			{
				title: t('admin.security'),
				visible: securityItems.length && isAdmin,
				state: securityItems.length ? securityItems[0].state : null,
				keywords: [t('admin.security').toLowerCase()],
				children: securityItems
			},
			{
				title: t('admin.currencies'),
				visible:
					isAdmin &&
					FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM) &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.ADMIN_CURRENCIES),
				state: 'currencies',
				keywords: [t('admin.currencies').toLowerCase()]
			},
			{
				title: t('admin.billingAndCredits'),
				visible: isBillingAdmin && (Tools.AppService.getCustomerId() !== 1 || Tools.ENV === 'DEV'),
				state: 'billing',
				keywords: [t('admin.billing').toLowerCase()]
			},
			{
				title: t('admin.accountSettings'),
				visible:
					isAdmin &&
					FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM) &&
					(FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') ||
						FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)),
				state: 'accountsettings',
				keywords: [t('admin.accountSettings').toLowerCase()]
			}
		];

		const hasRemoveActivities = Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		const appointmentAndActivitiesTitle = t(
			hasRemoveActivities ? 'admin.appointments' : 'admin.appointmentsAndTypes'
		);
		const activitiesTitle = t(
			hasRemoveActivities && Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
				? 'default.appointmentTypes'
				: 'admin.activityAndAppointmentTypes'
		);

		this.crmItems = [
			{
				title: t('admin.fields'),
				visible: isAdmin,
				state: 'fields',
				stateParams: { type: 'account' },
				keywords: [t('admin.fields').toLowerCase()],
				children: []
			},
			{
				title: Tools.FeatureHelper.hasSoftDeployAccess('AUTO_FILL_APPOINTMENTS_DESCRIPTION_SETTING')
					? appointmentAndActivitiesTitle
					: activitiesTitle,
				visible: isAdmin && FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM),
				state: 'activityTypes',
				keywords: [
					t('default.activities').toLowerCase(),
					t('default.appointments').toLowerCase(),
					t('admin.appointmentsAndTypes').toLowerCase(),
					t('default.appointmentTypes').toLowerCase(),
					t('default.activityTypes').toLowerCase(),
					t('default.activity').toLowerCase()
				]
			},
			{
				title: t('default.agreement'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER),
				state: 'agreement',
				keywords: [t('default.agreement').toLowerCase()]
			},
			{
				title: t('admin.prospecting.title'),
				visible:
					isAdmin &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO) &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_SIGNALS),
				state: 'prospectingSignals',
				keywords: [t('admin.prospecting'), t('admin.prospecting.signals')]
			},
			{
				title: t('default.categories'),
				visible: isAdmin,
				state: 'category',
				stateParams: { type: 'account' },
				keywords: [t('default.categories').toLowerCase()],
				children: [
					{
						title: t('default.account'),
						visible: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
						state: 'category',
						stateParams: { type: 'account' },
						keywords: [t('default.account').toLowerCase()]
					},
					{
						title: t('default.contact'),
						visible: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
						state: 'category',
						stateParams: { type: 'contact' },
						keywords: [t('default.contact').toLowerCase()]
					}
				]
			},
			{
				title: t('default.products'),
				visible: FeatureHelper.isAvailable(FeatureHelper.Feature.ADMIN_PRODUCTS) && isAdmin,
				state: 'products',
				keywords: [t('default.products').toLowerCase(), t('default.product').toLowerCase()],
				children: [
					{
						title: t('default.priceLists'),
						visible:
							isAdmin &&
							Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
							Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS),
						state: 'priceLists',
						keywords: [t('default.priceLists').toLowerCase()]
					}
				]
			},
			{
				title: t('admin.document.title'),
				visible: isAdmin,
				state: 'documentTemplates',
				keywords: [t('admin.document.title').toLowerCase()]
			},
			{
				title: t('default.goalAndBudget'),
				visible: isAdmin || isTeamleader,
				state: shouldShowOldActivityQuota
					? 'activityquota'
					: FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER)
					? 'subscriptionquota'
					: 'salesquota',
				keywords: [t('default.goalAndBudget').toLowerCase()]
			},
			{
				title: t('admin.teamMailSignature.title'),
				visible: FeatureHelper.isAvailable(FeatureHelper.Feature.MAIL_SIGNATURE) && isAdmin,
				state: 'mailsignature',
				keywords: [t('admin.teamMailSignature.title').toLowerCase()]
			},
			{
				title: t('filters.sharedViews'),
				visible: FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS) && isAdmin,
				state: 'sharedviews',
				keywords: [t('filters.sharedViews').toLowerCase()]
			},
			{
				title: t('default.projectPlanTemplates'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECT_PLAN),
				state: 'projectplan',
				keywords: [t('default.projectPlanTemplates').toLowerCase()]
			}
		];

		this.maItems = [
			{
				title: t('default.siteVisits'),
				visible: isMaAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.VISITS),
				state: 'visitSettings',
				keywords: [t('default.siteVisits').toLowerCase()],
				children: [
					{
						title: t('default.rejectList'),
						visible: isMaAdmin,
						state: 'marketRejectlist',
						keywords: [t('default.rejectList').toLowerCase()]
					}
				]
			},
			{
				title: t('admin.accountProfile'),
				visible: isMaAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_PROFILE),
				state: 'accountProfile',
				keywords: [t('admin.accountProfile').toLowerCase()]
			},
			{
				title: t('admin.domains.title'),
				visible:
					isMaAdmin &&
					(FeatureHelper.isAvailable(FeatureHelper.Feature.LANDINGPAGE_CUSTOM_DOMAIN) ||
						FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) ||
						FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT) ||
						FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL_BACKEND)),
				state: 'domains',
				keywords: [t('admin.domains.title').toLowerCase()]
			},
			{
				title: t('default.mailsettings'),
				visible:
					isMaAdmin &&
					(FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) ||
						FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL_BACKEND)),
				state: 'mailSettings',
				keywords: [t('default.mailsettings').toLowerCase()],
				children: [
					{
						title: t('default.optOutCategories'),
						visible: isMaAdmin,
						state: 'optOut',
						keywords: [t('default.optOutCategories').toLowerCase()]
					}
				]
			},
			{
				title: t('default.optInAndTerms'),
				visible: FeatureHelper.isAvailable(FeatureHelper.Feature.OPT_IN),
				state: 'optIn',
				keywords: [t('default.optInAndTerms').toLowerCase()]
			},
			{
				title: t('default.brands'),
				visible: isAdmin && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS),
				state: 'brand',
				keywords: [t('default.brands').toLowerCase()]
			},
			{
				title: t('admin.sharedAppointmentAvailability'),
				visible:
					isMaAdmin &&
					FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM) &&
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EASY_BOOKING_PRO),
				state: 'sharedAppointmentAvailability',
				keywords: [t('admin.sharedAppointmentAvailability').toLowerCase()]
			}
		];

		this.followupItems = [
			{
				title: t('admin.looker'),
				visible:
					FeatureHelper.isAvailable(FeatureHelper.Feature.LOOKER) ||
					this.metadata.params.LookerDashboardExeptionIds,
				state: 'looker',
				keywords: [t('admin.looker').toLowerCase()]
			}
		];
		this.supportItems = [
			{
				title: t('admin.ticketRules'),
				visible: hasSupportAndIsAdmin && hasTicketRules,
				state: 'ticketRules',
				keywords: [t('admin.ticketRules').toLowerCase()]
			},
			{
				title: t('admin.emailSetUp'),
				visible: hasSupportAndIsAdmin,
				state: 'customerSupport',
				keywords: [t('admin.emailSetUp').toLowerCase()]
			},
			{
				title: t('admin.ticketFields'),
				visible: hasSupportAndIsAdmin,
				state: 'ticketFields',
				keywords: [t('admin.ticketFields').toLowerCase()]
			},
			{
				title: t('admin.ticketResponseTemplates'),
				visible: hasSupport && hasTicketResponseTemplates,
				state: 'ticketResponseTemplates',
				keywords: [t('admin.ticketResponseTemplates').toLowerCase()]
			}
		];
		this.pipelineItems = [
			{
				title: t('default.stages'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.STAGES_ADMIN),
				state: 'orderstages',
				keywords: [t('default.stages').toLowerCase()]
			},
			{
				title: t('admin.salesProcess'),
				visible: isAdmin && FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM),
				state: 'salesprocesses',
				keywords: [t('admin.salesProcess').toLowerCase(), 'salesprocess', 'sales', 'process']
			},
			{
				title: t('default.salesboard'),
				visible: isAdmin,
				state: 'listSalesboards',
				keywords: [t('default.salesboard').toLowerCase()],
				children: [
					{
						title: t('salesboardCard.cardLayout'),
						visible: isAdmin,
						state: 'salesboardCard',
						children: []
					}
				]
			}
		];
	},
	componentDidMount: function () {
		var self = this;
		var avatarService = Tools.avatarService;
		var user = self.props.rootData.self;
		avatarService
			.getAvatar(user)
			.then(function (avatar) {
				self.setState({ avatar: avatar });
			})
			.catch(e => console.log('avatar error', e));
		if (this.selectedSubItem) {
			this.setState({
				expandedSubSections: this.state.expandedSubSections.concat([this.selectedSubItem])
			});
		}

		const activeSection =
			this.getIsActiveSection(this.accountItems, 'account') ||
			this.getIsActiveSection(this.crmItems, 'crm') ||
			this.getIsActiveSection(this.maItems, 'marketingAutomation') ||
			this.getIsActiveSection(this.followupItems, 'followup') ||
			this.getIsActiveSection(this.supportItems, 'customerSupport') ||
			this.getIsActiveSection(this.pipelineItems, 'pipeline');

		if (activeSection && !this.state.expandedSections.includes(activeSection)) {
			this.setState({ expandedSections: this.state.expandedSections.concat([activeSection]) });
		}
	},
	getIsActiveSection: function (items, sectionName) {
		const isActiveSection = items.some(item => {
			const levelOneExpanded = this.props.rootData.isSelectedState(
				`administration.${item.state}`,
				{},
				{ ignoreParams: true }
			);
			if (levelOneExpanded || !item.children) {
				return levelOneExpanded;
			}
			const levelTwoExpanded = item.children.some(child => {
				return this.props.rootData.isSelectedState(`administration.${child.state}`, {}, { ignoreParams: true });
			});
			return levelTwoExpanded;
		});
		return isActiveSection ? sectionName : null;
	},
	getBusinessRules: function () {
		var businessRules = [];
		var FeatureHelper = Tools.FeatureHelper;
		var t = Tools.$translate;

		if (FeatureHelper.isAvailable(FeatureHelper.Feature.TRIGGERS)) {
			businessRules.push({
				title: t('feature.triggers'),
				visible: true,
				state: 'triggers',
				keywords: [t('feature.triggers').toLowerCase()]
			});
		}
		if (FeatureHelper.isAvailable(FeatureHelper.Feature.AUTOMATIONS)) {
			businessRules.push({
				title: t('admin.automations'),
				visible: true,
				state: 'automations',
				keywords: [t('admin.automations').toLowerCase()]
			});
		}
		if (FeatureHelper.isAvailable(FeatureHelper.Feature.DYNAMIC_LINKS)) {
			businessRules.push({
				title: t('default.dynamiclinks'),
				visible: true,
				state: 'dynamiclinks',
				keywords: [t('default.dynamiclinks').toLowerCase()]
			});
		}
		if (FeatureHelper.isAvailable(FeatureHelper.Feature.UI_SCRIPTS)) {
			businessRules.push({
				title: t('default.script'),
				visible: true,
				state: 'scripts',
				keywords: [t('default.script').toLowerCase()],
				extraStates: ['script']
			});
		}
		if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
			businessRules.push({
				title: t('default.journeyStatuses'),
				visible: true,
				state: 'journeyStatuses',
				keywords: [t('default.journeyStatuses').toLowerCase()],
				extraStates: ['journeyEditor']
			});
		}

		return businessRules;
	},
	getAccountSettingsItems: function () {
		return [];
	},
	getSecurityItems: function () {
		var t = Tools.$translate;
		var isAdmin = this.props.rootData.self.administrator;
		var FeatureHelper = Tools.FeatureHelper;

		var securityItems = [];

		if (isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.SMSAUTH)) {
			securityItems.push({
				title: t('feature.smsAuth'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.SMSAUTH),
				state: '2fa',
				keywords: [t('feature.smsAuth').toLowerCase(), '2fa']
			});
		}
		if (isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.API_KEYS)) {
			securityItems.push({
				title: t('admin.apiKeys'),
				visible: isAdmin,
				state: 'apiKeys',
				keywords: [t('admin.apiKeys').toLowerCase()]
			});
		}
		if (isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.IP_RESTRICTIONS)) {
			securityItems.push({
				title: t('admin.ipblock'),
				visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.IP_RESTRICTIONS),
				state: 'ipblock',
				keywords: ['ip block', t('admin.ipblock').toLowerCase()]
			});
		}
		securityItems.push({
			title: t('admin.deleteLog'),
			visible: isAdmin,
			state: 'deleteLog',
			keywords: [t('admin.deleteLog').toLowerCase()]
		});
		securityItems.push({
			title: t('admin.singleSignOn'),
			visible: isAdmin && FeatureHelper.isAvailable(FeatureHelper.Feature.SINGLE_SIGN_ON),
			state: 'singleSignOn',
			keywords: [
				'single sign on',
				'sso',
				'saml',
				'ad',
				'active directory',
				'google apps',
				t('admin.singleSignOn').toLowerCase()
			]
		});
		securityItems.push({
			title: t('admin.loginStatistics'),
			visible: isAdmin,
			state: 'loginStats',
			keywords: ['login', 'log', t('admin.loginStatistics').toLowerCase()]
		});

		return securityItems;
	},
	clearSelected: function () {
		var selected = document.querySelector('.highlighted-item');
		if (selected) {
			selected.classList.remove('highlighted-item');
		}
	},
	onKeyDown: function (e) {
		var self = this;
		var keyCode = e.keyCode;
		if ([40, 38].indexOf(e.keyCode) !== -1) {
			e.preventDefault();
		}
		setTimeout(function () {
			// If search is fucused and we have a qs
			if (
				ReactDOM.findDOMNode(self._search) === document.activeElement &&
				ReactDOM.findDOMNode(self._search).value &&
				ReactDOM.findDOMNode(self._search).value.length
			) {
				var results = document.querySelectorAll('#sidebar-list-wrap .searchable-item');
				// if escape
				if (keyCode === 27) {
					self.clearSelected();
					self.setState({ searchStr: '' });
					ReactDOM.findDOMNode(self._search).blur();
					return;
				}

				if (!results.length) {
					return;
				}

				results = Array.prototype.slice.call(results);

				// Find selected row
				var selectedRow = document.querySelector('#sidebar-list-wrap .highlighted-item');

				// if enter
				if (keyCode === 13) {
					self.clearSelected();
					if (selectedRow) {
						selectedRow.click();
						ReactDOM.findDOMNode(self._search).blur();
					}
					return;
				}
				if (!selectedRow) {
					// highlight first result
					results[0].classList.add('highlighted-item');
				}

				var currentIndex = results.indexOf(selectedRow);

				// move up or down
				if (keyCode === 40 && currentIndex !== results.length - 1) {
					//down
					self.clearSelected();
					currentIndex++;
					results[currentIndex].classList.add('highlighted-item');
				}
				if (keyCode === 38 && currentIndex !== 0) {
					// up
					self.clearSelected();
					currentIndex--;
					results[currentIndex].classList.add('highlighted-item');
				}
			} else {
				// Remove selected row if there is one
				self.clearSelected();
			}
		});
	},
	onError: function () {
		this.setState({
			avatar: Tools.avatarService.getGravatar(this.props.rootData.self)
		});
	},
	getFieldChild: function (type, title, visible, available = true) {
		var t = Tools.$translate;
		return {
			title: t(title),
			visible: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature[visible]) && available,
			state: 'fields',
			dataTestId: 'field-' + type,
			stateParams: { type: type },
			keywords: [t(title).toLowerCase()]
		};
	},
	getFieldChildren: function () {
		var udos = this.metadata.params.UserDefinedObject;

		var fieldChildren = [
			this.getFieldChild('account', 'default.account', 'CLIENT_CUSTOM'),
			this.getFieldChild('contact', 'default.contact', 'CONTACT_CUSTOM'),
			this.getFieldChild(
				'activity',
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? 'admin.fields.phonecalls' : 'default.activity',
				'ACTIVITY_CUSTOM'
			),
			this.getFieldChild('todo', 'default.todos', 'ACTIVITY_CUSTOM', hasTodoCustomFields()),
			this.getFieldChild('appointment', 'default.appointment', 'APPOINTMENT_CUSTOM'),
			this.getFieldChild('orderAndOpportunities', 'admin.orderAndOpportunities', 'ORDER_CUSTOM'),
			this.getFieldChild('orderrow', 'default.orderrow', 'ORDER_ROW_CUSTOM'),
			this.getFieldChild('product', 'default.product', 'PRODUCT_CUSTOM_FIELDS'),
			this.getFieldChild('campaign', 'default.campaign', 'PROJECT_CUSTOM'),
			this.getFieldChild('user', 'default.user', 'USER_CUSTOM'),
			this.getFieldChild('agreement', 'default.agreement', 'RECURRING_ORDER_CUSTOM'),
			this.getFieldChild('projectPlan', 'default.projectPlan', 'PROJECT_PLAN_CUSTOM'),
			this.getFieldChild(
				'ticket',
				'default.ticket',
				'CUSTOMER_SUPPORT',
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT)
			)
		];

		_.each(udos, function (udo) {
			var name = (udo.name || '').trim();

			fieldChildren.push({
				title: name || Tools.$translate('default.noName'),
				visible: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.UDO),
				state: 'fields',
				stateParams: { type: 'userDefined' + udo.id }
			});
		});

		return fieldChildren;
	},
	filterItems: function (item) {
		var qs = this.state.searchStr;
		if (!qs || !qs.length) {
			return true;
		}

		qs = qs.toLowerCase();

		var match = false;

		_.each(item.keywords, function (keyword) {
			if (keyword.toLowerCase().indexOf(qs) !== -1) {
				match = true;
			}
		});

		return match;
	},
	filterChildren: function (parentMatch, child) {
		if (!child.visible) {
			return false;
		}
		var qs = this.state.searchStr;
		if (!qs || !qs.length || parentMatch) {
			return true;
		}

		qs = qs.toLowerCase();

		var match = false;

		_.each(child.keywords, function (keyword) {
			if (keyword.toLowerCase().indexOf(qs) !== -1) {
				match = true;
			}
		});

		return match;
	},
	onSearch: function (e) {
		this.setState({ searchStr: e.target.value });
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	setActiveSection: function (section) {
		var sections = this.state.expandedSections;
		var index = this.state.expandedSections.indexOf(section);
		if (index !== -1) {
			sections.splice(index, 1);
		} else {
			sections.push(section);
		}
		this.setState({ expandedSections: sections });
		this.localStorage.setValue(expandedSectionLSKey, sections);
	},
	highlight: function (string) {
		return string.replace(
			// eslint-disable-next-line security/detect-non-literal-regexp
			new RegExp('(' + this.state.searchStr + ')', 'gi'),
			'<span class="highlighted">$1</span>'
		);
	},
	renderChildItem: function (childSelected, child, i) {
		var self = this;
		var title = child.title;
		var classes = 'sub-item searchable-item';

		if (this.state.searchStr && this.state.searchStr.length) {
			title = this.highlight(title);
		}

		var isExpanded =
			this.props.rootData.isSelectedState('administration.' + child.state, child.stateParams || {}) ||
			_.some(child.extraStates, function (extraState) {
				return self.props.rootData.isSelectedState('administration.' + extraState, {}, { ignoreParams: true });
			});

		if (isExpanded) {
			childSelected.selected = true;
			classes += ' active';
		}

		return (
			<div
				key={'child-' + i}
				className={classes}
				onClick={this.setPage.bind(this, child)}
				data-test-id={'admin-sidebar-sub-item-' + (child.dataTestId ? child.dataTestId : child.state)}
			>
				<span dangerouslySetInnerHTML={{ __html: title }} />
			</div>
		);
	},
	renderItem: function (item, i) {
		if (!item.visible) {
			return null;
		}
		var qs = this.state.searchStr;
		var hasChildren = item.children && _.filter(item.children, 'visible').length;
		var selected = this.props.rootData.isSelectedState('administration.' + item.state, item.stateParams || {});
		var children = null;
		var className = 'sidebar-item';
		if (selected) {
			className += ' active';
		}

		var matchParent = true;
		if (qs && qs.length) {
			matchParent = false;

			qs = qs.toLowerCase();

			_.each(item.keywords, function (keyword) {
				if (keyword.toLowerCase().indexOf(qs) !== -1) {
					matchParent = true;
				}
			});
		}

		if (hasChildren) {
			var subClassName = 'sub-items';
			var childSelected = {
				selected: false
			};
			var childItems = _.chain(item.children)
				.filter(this.filterChildren.bind(this, matchParent))
				.map(this.renderChildItem.bind(this, childSelected))
				.value();

			if (this.state.expandedSubSections.indexOf(item.title) > -1) {
				className += ' expanded';
			} else if (qs && qs.length && childItems.length) {
				className += ' expanded';
			} else if (!this.selectedSubItem && (selected || childSelected.selected)) {
				this.selectedSubItem = item.title;
			}

			if (childItems.length) {
				matchParent = true;
			}

			children = <div className={subClassName}>{childItems}</div>;
		}

		if (matchParent) {
			return (
				<div key={'item-' + i} className={className}>
					<div
						className="item-title searchable-item"
						onClick={this.setPage.bind(this, item)}
						data-test-id={'admin-sidebar-item-' + item.state}
					>
						{item.title}
						{hasChildren ? (
							<b
								className="fa fa-angle-down"
								onClick={this.toggleChildren.bind(this, item, className.indexOf('expanded') > -1)}
							/>
						) : null}
					</div>
					{children}
				</div>
			);
		}

		return null;
	},
	toggleChildren: function (item, isExpanded, e) {
		e.stopPropagation();

		if (!isExpanded) {
			this.setState({
				expandedSubSections: this.state.expandedSubSections.concat([item.title])
			});
		} else {
			var expandedSubSections = _.filter(this.state.expandedSubSections, function (s) {
				return s !== item.title;
			});

			this.setState({ expandedSubSections: expandedSubSections });
		}
	},
	setPage: function (item) {
		if (this.state.searchStr && this.state.searchStr.length) {
			this.setState({ searchStr: '' });
		}
		if (item.children && item.children.length && this.state.expandedSubSections.indexOf(item.title) < 0) {
			this.setState({
				expandedSubSections: this.state.expandedSubSections.concat([item.title])
			});
		}
		this.clearSelected();
		this.props.rootData.setPage(item.state, item.stateParams || {});
	},
	clearSearch: function () {
		this.clearSelected();
		this.setState({ searchStr: '' });
	},
	itemClass: function (item) {
		var className = 'sidebar-item searchable-item';

		if (this.props.rootData.isSelectedState('administration.' + item.state, item.stateParams || {})) {
			className += ' active';
		}

		return className;
	},
	renderProfileSection: function () {
		var self = this;
		var isSearching = this.state.searchStr && this.state.searchStr.length;

		var userIntegrations = [];
		_.each(this.props.rootData.integrations.userConfigurable, function (integration, i) {
			// If search but no match
			if (isSearching && integration.name.toLowerCase().indexOf(self.state.searchStr.toLowerCase()) === -1) {
				return;
			}
			var item = {
				state: 'integration',
				stateParams: { id: integration.id.toString(), configure: 'user', tab: null }
			};
			var appIcon = {
				backgroundColor: integration.color || '#ffffff',
				backgroundImage: 'url(' + integration.imageLink + ')'
			};
			var title = isSearching ? self.highlight(integration.name) : integration.name;
			userIntegrations.push(
				<div
					key={'uint' + i}
					className={self.itemClass(item) + ' sidebar-app-item'}
					onClick={self.setPage.bind(self, item)}
				>
					<div className="item-title">
						<div className="app-img-wrapper" style={appIcon} />
						<span dangerouslySetInnerHTML={{ __html: title }} />
					</div>
				</div>
			);
		});

		// if we are not searching or searched and matched "my profile" or had any match in the userIntegrations
		if (
			isSearching &&
			this.lang.myProfile.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1 &&
			this.lang.notificationCenterTitle.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1 &&
			this.lang.appointmentAvailabilityTitle.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1 &&
			this.lang.mailSignatureTitle.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1 &&
			this.lang.remindersTitle.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1 &&
			!userIntegrations.length
		) {
			return null;
		}

		var currentUser = Tools.AppService.getSelf();
		var title = isSearching ? this.highlight(this.lang.myProfile) : this.lang.myProfile;
		const remindersTitle = isSearching ? this.highlight(this.lang.remindersTitle) : this.lang.remindersTitle;
		const notificationCenterTitle = isSearching
			? this.highlight(this.lang.notificationCenterTitle)
			: this.lang.notificationCenterTitle;
		const appointmentAvailabilityTitle = isSearching
			? this.highlight(this.lang.appointmentAvailabilityTitle)
			: this.lang.appointmentAvailabilityTitle;
		const mailSignatureTitle = isSearching
			? this.highlight(this.lang.mailSignatureTitle)
			: this.lang.mailSignatureTitle;

		var avatar = (
			<span>
				<window.UiAvatar
					initials={currentUser.name}
					src={self.state.avatar.url}
					email={currentUser.email}
					size="sm"
					style={{ margin: '0 5px -4px 0' }}
				/>
				<span dangerouslySetInnerHTML={{ __html: title }} />
			</span>
		);

		var profileItemClass = 'sidebar-item searchable-item';
		if (this.props.rootData.state === 'profile') {
			profileItemClass += ' active';
		}

		return (
			<div className="sidebar-main-section expanded" style={{ marginTop: '10px' }}>
				<div className="sidebar-items" style={{ marginRight: 0 }}>
					<div
						id="admin-sidebar-profile-item"
						className={profileItemClass}
						onClick={this.setPage.bind(this, { state: 'profile' })}
					>
						<div className="item-title"> {avatar} </div>
					</div>
					{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.NOTIFICATIONS) ? (
						<div
							className={
								this.props.rootData.state === 'notificationCenter'
									? 'sidebar-item active'
									: 'sidebar-item'
							}
							onClick={this.setPage.bind(this, { state: 'notificationCenter' })}
						>
							<div className="item-title">
								<span dangerouslySetInnerHTML={{ __html: notificationCenterTitle }} />
							</div>
						</div>
					) : null}

					{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.REMINDERS) ? (
						<div
							className={
								this.props.rootData.state === 'reminders' ? 'sidebar-item active' : 'sidebar-item'
							}
							onClick={this.setPage.bind(this, { state: 'reminders' })}
						>
							<div className="item-title">
								<span dangerouslySetInnerHTML={{ __html: remindersTitle }} />
							</div>
						</div>
					) : null}

					{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EASY_BOOKING) ? (
						<div
							className={
								this.props.rootData.state === 'appointmentAvailability'
									? 'sidebar-item active'
									: 'sidebar-item'
							}
							onClick={this.setPage.bind(this, { state: 'appointmentAvailability' })}
						>
							<div className="item-title">
								<span dangerouslySetInnerHTML={{ __html: appointmentAvailabilityTitle }} />
							</div>
						</div>
					) : null}

					{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE) ? (
						<div
							className={
								this.props.rootData.state === 'defaultMailSignature'
									? 'sidebar-item active'
									: 'sidebar-item'
							}
							onClick={this.setPage.bind(this, { state: 'defaultMailSignature' })}
						>
							<div className="item-title">
								<span dangerouslySetInnerHTML={{ __html: mailSignatureTitle }} />
							</div>
						</div>
					) : null}

					{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INTEGRATIONS)
						? userIntegrations
						: null}
				</div>
			</div>
		);
	},

	renderAppRow: function (integration, key) {
		// If search but no match
		var qs = this.state.searchStr;
		if (qs && qs.length && integration.name.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) === -1) {
			return null;
		}
		var title = qs && qs.length ? this.highlight(integration.name) : integration.name;
		var item = {
			state: 'integration',
			stateParams: { id: integration.id.toString(), configure: 'client', tab: null }
		};
		var appIcon = {
			backgroundColor: integration.color || '#ffffff',
			backgroundImage: 'url(' + integration.imageLink + ')'
		};
		return (
			<div
				key={key}
				className={this.itemClass(item) + ' sidebar-app-item'}
				onClick={this.setPage.bind(this, item)}
			>
				<div className="item-title">
					<div className="app-img-wrapper" style={appIcon} />
					<span dangerouslySetInnerHTML={{ __html: title }} />
				</div>
			</div>
		);
	},

	render: function () {
		var self = this;
		var t = Tools.$translate;
		var rootData = this.props.rootData;
		var qs = this.state.searchStr;
		var sectionClass = 'sidebar-main-section';
		var sectionExpandedClass = 'sidebar-main-section expanded';
		var accountSectionExpanded = self.state.expandedSections.indexOf('account') !== -1;
		var crmSectionExpanded = self.state.expandedSections.indexOf('crm') !== -1;
		var supportSectionExpanded = self.state.expandedSections.indexOf('customerSupport') !== -1;
		var maSectionExpanded = self.state.expandedSections.indexOf('marketingAutomation') !== -1;
		var followupSectionExpanded = self.state.expandedSections.indexOf('followup') !== -1;
		var pipelineSectionExpanded = self.state.expandedSections.indexOf('pipeline') !== -1;
		var hasSearched = false;
		var isAdmin = rootData.self.administrator;
		var isTeamleader = rootData.self.teamLeader;
		var isMarketAdmin = rootData.self.userParams.mailAdmin;
		var iap = Tools.FeatureHelper.isAvailableProduct;
		var Product = Tools.FeatureHelper.Product;
		var hasCrmSection = (isAdmin || isTeamleader) && iap(Product.CRM);
		var hasMaSection = (isAdmin || isMarketAdmin) && _.some(self.maItems, item => item.visible);
		var profileSection = this.renderProfileSection();

		var hasFollowupSection =
			isAdmin &&
			(Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LOOKER) ||
				this.metadata.params.LookerDashboardExeptionIds);

		var crmItems = [];
		var maItems = [];
		var followupItems = [];

		const hasGoals = Tools.FeatureHelper.hasSoftDeployAccess('GOALS');
		const hasToDo = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		const hasSubscriptionIndexing = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_INDEXING');
		const hasNewSubscriptionIndexing = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_INDEX_INCREASE_V2');
		const hasSupport = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT);

		const shouldShowOldActivityQuota = !(hasGoals && hasToDo);

		self.crmItems.forEach(item => {
			if (item.state === 'agreement') {
				if (hasNewSubscriptionIndexing) {
					item.children = [
						{
							title: t('admin.subscriptionIndexing'),
							visible: isAdmin,
							state: 'subscriptionIndexingList',
							keywords: [t('admin.subscriptionIndexing').toLowerCase(), 'index']
						}
					];
				} else if (hasSubscriptionIndexing) {
					item.children = [
						{
							title: t('admin.subscriptionIndexing'),
							visible: isAdmin,
							state: 'subscriptionIndexing',
							keywords: [t('admin.subscriptionIndexing').toLowerCase(), 'index']
						}
					];
				}
			}
			if (item.state === 'activityquota' || item.state === 'salesquota' || item.state === 'subscriptionquota') {
				const quotaChildren = [];
				if (shouldShowOldActivityQuota) {
					quotaChildren.push({
						title: t('default.activityQuota'),
						visible: isAdmin || isTeamleader,
						state: 'activityquota',
						keywords: [t('default.activityQuota').toLowerCase()]
					});
				}

				if (
					// Remove if-statement if we remove flag
					Tools.FeatureHelper.hasSoftDeployAccess('COMBINE_RR_AND_CM') ||
					this.metadata.params.SalesModel !== 'rr'
				) {
					quotaChildren.push({
						title: t('campaign.quota'),
						visible: isAdmin || isTeamleader,
						state: 'salesquota',
						keywords: [t('campaign.quota').toLowerCase(), t('campaign.quota').toLowerCase()]
					});
				}

				if (this.metadata.params.SalesModel === 'rr') {
					quotaChildren.push({
						title: t('admin.subscriptionQuota'),
						visible:
							(isAdmin || isTeamleader) &&
							Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER),
						state: 'subscriptionquota',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.subscriptionQuota').toLowerCase()]
					});

					quotaChildren.push({
						title: t('admin.oneoffQuota'),
						visible: isAdmin || isTeamleader,
						state: 'oneoffquota',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.oneoffQuota').toLowerCase()]
					});

					if (hasRRWithCM()) {
						quotaChildren.push({
							title: t('admin.salesMarginQuota'),
							visible: isAdmin || isTeamleader,
							state: 'salesmarginquota',
							keywords: [t('campaign.quota').toLowerCase(), t('admin.salesMarginQuota').toLowerCase()]
						});
					}
				} else if (this.metadata.params.SalesModel === 'cm') {
					quotaChildren.push({
						title: t('admin.salesMarginQuota'),
						visible: isAdmin || isTeamleader,
						state: 'salesmarginquota',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.salesMarginQuota').toLowerCase()]
					});

					if (hasCMWithRR()) {
						quotaChildren.push({
							title: t('admin.subscriptionQuota'),
							visible:
								(isAdmin || isTeamleader) &&
								Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER),
							state: 'subscriptionquota',
							keywords: [t('campaign.quota').toLowerCase(), t('admin.subscriptionQuota').toLowerCase()]
						});

						quotaChildren.push({
							title: t('admin.oneoffQuota'),
							visible: isAdmin || isTeamleader,
							state: 'oneoffquota',
							keywords: [t('campaign.quota').toLowerCase(), t('admin.oneoffQuota').toLowerCase()]
						});
					}
				}

				if (hasGoals) {
					quotaChildren.push({
						title: t('admin.pipelineGoals'),
						visible: isAdmin || isTeamleader,
						state: 'pipelinegoals',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.pipelineGoals').toLowerCase()]
					});
					quotaChildren.push({
						title: t('admin.phonecallGoals'),
						visible: isAdmin || isTeamleader,
						state: 'phonecallgoals',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.phonecallGoals').toLowerCase()]
					});
					quotaChildren.push({
						title: t('admin.bookedAppointments'),
						visible: isAdmin || isTeamleader,
						state: 'bookedappointmentgoals',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.bookedAppointments').toLowerCase()]
					});
					quotaChildren.push({
						title: t('admin.completedAppointments'),
						visible: isAdmin || isTeamleader,
						state: 'completedappointmentgoals',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.completedAppointments').toLowerCase()]
					});
					quotaChildren.push({
						title: t('admin.bookedFirstAppointments'),
						visible: isAdmin || isTeamleader,
						state: 'bookedfirstappointmentgoals',
						keywords: [t('campaign.quota').toLowerCase(), t('admin.bookedFirstAppointments').toLowerCase()]
					});
					quotaChildren.push({
						title: t('admin.completedFirstAppointments'),
						visible: isAdmin || isTeamleader,
						state: 'completedfirstappointmentgoals',
						keywords: [
							t('campaign.quota').toLowerCase(),
							t('admin.completedFirstAppointments').toLowerCase()
						]
					});
				}
				item.children = quotaChildren;
			}
		});
		var accountItems = _.compact(_.map(self.accountItems, self.renderItem));
		var supportItems = _.compact(_.map(self.supportItems, self.renderItem));
		var pipelineItems = _.compact(_.map(self.pipelineItems, self.renderItem));

		var fieldConf = _.find(self.crmItems, { state: 'fields' });
		fieldConf.children = this.getFieldChildren();

		if (hasCrmSection) {
			crmItems = _.compact(_.map(self.crmItems, self.renderItem));
		}

		if (hasMaSection) {
			maItems = _.compact(_.map(self.maItems, self.renderItem));
		} else if (Tools.AppService.getAccountSelf().version === 'Pro') {
			var reducedMaItems = _.filter(self.maItems, function (item) {
				item.visible = true;
				return item.state === 'mailSettings';
			});

			maItems = _.compact(_.map(reducedMaItems, self.renderItem));
		}

		if (hasFollowupSection) {
			followupItems = _.compact(_.map(self.followupItems, self.renderItem));
		}

		// If we have a queryString and there is results we expand the sections
		if (qs && qs.length) {
			hasSearched = true;
			if (accountItems.length) {
				accountSectionExpanded = true;
			}
			if (hasCrmSection && crmItems.length) {
				crmSectionExpanded = true;
			}
			if (hasMaSection && maItems.length) {
				maSectionExpanded = true;
			}
			if (hasFollowupSection && followupItems.length) {
				followupSectionExpanded = true;
			}
			if (hasSupport && supportItems.length) {
				supportSectionExpanded = true;
			}
			if (hasCrmSection && pipelineItems.length) {
				pipelineSectionExpanded = true;
			}
		}

		var globalAppsVisible = false;
		var globalIntegrations = [];

		_.each(rootData.integrations.global, function (integration) {
			var row = self.renderAppRow(integration, 'gint' + integration.id);
			if (row) {
				globalIntegrations.push(row);
			}
		});

		if (
			globalIntegrations.length ||
			(qs &&
				qs.length &&
				(self.lang.apps.toLowerCase().indexOf(qs.toLowerCase()) !== -1 ||
					self.lang.apps.toLowerCase().indexOf(qs.toLowerCase()) !== -1))
		) {
			globalAppsVisible = true;
		}

		var noResults = null;
		if (
			!profileSection &&
			!accountItems.length &&
			!(hasCrmSection && crmItems.length) &&
			!(hasMaSection && maItems.length) &&
			!(hasFollowupSection && followupItems.length) &&
			!(hasSupport && supportItems.length) &&
			!pipelineItems.length &&
			!globalAppsVisible
		) {
			noResults = <div id="admin-sidebar-noresults">{self.lang.noResults}</div>;
		}

		return (
			<div className="admin-sidebar">
				<div id="admin-sidebar-search">
					<b className="fa fa-search" />
					<window.Input.input
						value={self.state.searchStr}
						onChange={self.onSearch}
						onKeyDown={this.onKeyDown}
						placeholder={self.lang.search}
						ref={this.setRef.bind(this, '_search')}
					/>
					{hasSearched ? (
						<div className="clear-search" onClick={self.clearSearch}>
							<b className="fa fa-times-circle" />
						</div>
					) : null}
				</div>

				{noResults}

				<div id="sidebar-list-wrap">
					{profileSection}

					{accountItems.length ? (
						<div
							data-test-id="admin-account-items"
							className={accountSectionExpanded ? sectionExpandedClass : sectionClass}
							style={{ marginTop: '10px' }}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'account')}>
								{self.lang.account}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{accountItems}</div>
						</div>
					) : null}

					{hasCrmSection && crmItems.length ? (
						<div
							data-test-id="admin-crm-items"
							className={crmSectionExpanded ? sectionExpandedClass : sectionClass}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'crm')}>
								{'CRM'}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{crmItems}</div>
						</div>
					) : null}

					{hasCrmSection && pipelineItems.length ? (
						<div
							data-test-id="admin-pipeline-items"
							className={pipelineSectionExpanded ? sectionExpandedClass : sectionClass}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'pipeline')}>
								{self.lang.pipeline}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{pipelineItems}</div>
						</div>
					) : null}

					{(hasMaSection || (Tools.AppService.getAccountSelf().version === 'Pro' && maItems.length)) &&
					maItems.length ? (
						<div
							data-test-id="admin-ma-items"
							className={maSectionExpanded ? sectionExpandedClass : sectionClass}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'marketingAutomation')}>
								{self.lang.marketingAutomation}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{maItems}</div>
						</div>
					) : null}

					{hasFollowupSection && followupItems.length ? (
						<div
							data-test-id="admin-followup-items"
							className={followupSectionExpanded ? sectionExpandedClass : sectionClass}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'followup')}>
								{self.lang.followup}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{followupItems}</div>
						</div>
					) : null}

					{hasSupport && supportItems.length ? (
						<div
							data-test-id="admin-support-items"
							className={supportSectionExpanded ? sectionExpandedClass : sectionClass}
						>
							<div className="title" onClick={self.setActiveSection.bind(self, 'customerSupport')}>
								{self.lang.customerSupport}
								<b className="fa fa-angle-down" />
							</div>
							<div className="sidebar-items">{supportItems}</div>
						</div>
					) : null}

					{isAdmin && globalAppsVisible ? (
						<div data-test-id="admin-integrations-items" className={sectionExpandedClass}>
							<div className="title" onClick={self.setPage.bind(self, { state: 'integrations' })}>
								{self.lang.apps}
							</div>
							<div className="sidebar-items">
								{globalIntegrations}
								<div
									key="integrations"
									className="sidebar-item searchable-item"
									style={{ marginBottom: '100px' }}
									onClick={self.setPage.bind(self, { state: 'integrations' })}
								>
									<div className="item-title">{self.lang.findNewApps}</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
});
